<template>
  <graph
    v-if="account"
    class="aging-bar"
    width="100%"
    height="200px"
    :options="chartOptions"
    :series="series"
  />
</template>

<script>
export default {
  components: {
    graph: () => import('@/components/graph')
  },
  computed: {
    chartOptions () {
      return {
        chart: {
          type: 'bar'
        },
        dataLabels: {
          enabled: true,
          formatter: v => this.formatCurrency(v, this.account.currency),
          offsetY: -20
        },
        labels: this.account.aging.ranges.map(r => r.name),
        legend: {
          show: false
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top' // top, center, bottom
            }
          }
        },
        xaxis: {
          type: 'string',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          show: false
        },
        tooltip: {
          enabled: false
        }
      }
    },
    series () {
      const index = this.account.aging.days.length - 1
      return [{
        name: 'Balance',
        data: this.account.aging.ranges.map(r => r.data[index])
      }]
    }
  },
  props: {
    account: Object
  }
}
</script>
